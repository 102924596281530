import { cva } from 'class-variance-authority';
import { ButtonHTMLAttributes, Fragment, forwardRef } from 'react';

import cn from '../lib/cn';

export const buttonVariants = cva(
  'w-fit flex items-center justify-center font-medium leading-normal transition-colors',
  {
    variants: {
      size: {
        sm: 'px-3.5 gap-1 rounded-lg h-9 b3 [&_svg]:w-4 [&_svg]:h-4',
        md: 'px-5 gap-1.5 rounded-lg h-10 [&_svg]:w-5 [&_svg]:h-5',
        lg: 'px-6 gap-2.5 rounded-lg h-12 b2 [&_svg]:w-6 [&_svg]:h-6',
      },
      variant: {
        primary:
          'bg-content-brand-primary border border-content-brand-primary text-content-primary hover:bg-green-600 hover:border-green-600 active:bg-green-700 active:border-green-700 active:text-white',
        outline:
          'bg-green-50 border border-green text-content-primary hover:bg-green-200 active:bg-green-700 active:border-green-700 active:text-white',
        soft: 'bg-surface-tertiary border border-surface-tertiary text-content-primary hover:bg-surface-disabled hover:border-surface-disabled active:bg-gray-300 active:border-gray-300 active:text-content-primary',
        text: 'bg-transparent border border-transparent text-content-primary hover:text-content-brand-primary active:text-green-700',
        destructive:
          'bg-red border border-red text-white hover:bg-red-600 active:bg-red-700 active:border-red-700',
      },
      state: {
        success:
          'bg-green-700 border-green-700 text-white hover:bg-green-700 active:bg-green-700',
        error:
          'bg-red border-red text-white hover:bg-red active:bg-red active:border-red',
      },
      iconOnly: {
        true: '',
        false: '',
      },
      disabled: {
        true: 'cursor-not-allowed',
        false: '',
      },
    },
    compoundVariants: [
      // disabled
      {
        variant: 'primary',
        disabled: true,
        className:
          'disabled:bg-gray-200 disabled:border-gray-200 disabled:text-black-200',
      },
      {
        variant: 'outline',
        disabled: true,
        className:
          'disabled:bg-gray-50 disabled:border-gray-200 disabled:text-black-200',
      },
      {
        variant: 'soft',
        disabled: true,
        className:
          'disabled:bg-gray-200 disabled:border-gray-200 disabled:text-black-200',
      },
      {
        variant: 'text',
        disabled: true,
        className: 'disabled:text-black-200',
      },
      {
        variant: 'destructive',
        disabled: true,
        className:
          'disabled:bg-gray-200 disabled:border-gray-200 disabled:text-black-200',
      },
      // state
      {
        state: 'success',
        variant: 'outline',
        className:
          'bg-green-50 border-green-700 text-green-700 hover:bg-green-50 hover:border-green-700 hover:text-green-700 active:bg-green-50 active:border-green-700 active:text-green-700',
      },
      {
        state: 'error',
        variant: 'outline',
        className:
          'bg-red-50 border-red text-red hover:bg-red-50 hover:border-red hover:text-red active:bg-red-50 active:border-red active:text-red',
      },
      {
        state: 'success',
        variant: 'text',
        className:
          'bg-transparent border-transparent text-green-700 hover:bg-transparent hover:border-transparent hover:text-green-700 active:bg-transparent active:border-transparent active:text-green-700',
      },
      {
        state: 'error',
        variant: 'text',
        className:
          'bg-transparent border-transparent text-red hover:bg-transparent hover:border-transparent hover:text-red active:bg-transparent active:border-transparent active:text-red',
      },
      // iconOnly
      {
        size: 'lg',
        iconOnly: true,
        className: 'px-3.5',
      },
      {
        size: 'md',
        iconOnly: true,
        className: 'px-2.5',
      },
      {
        size: 'sm',
        iconOnly: true,
        className: 'px-[9px]',
      },
    ],
  },
);

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: 'sm' | 'md' | 'lg';
  variant?: 'outline' | 'soft' | 'primary' | 'text' | 'destructive';
  state?: 'success' | 'error';
  loading?: boolean;
  loadingComponent?: React.ReactNode;
  icon?: React.ReactNode;
  iconOnly?: boolean;
  iconPosition?: 'leading' | 'trailing';
};

const DefaultLoader = () => (
  <div className="border-2 border-gray-600 border-t-neutral-600 animate-spin w-6 h-6 rounded-full" />
);

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size = 'md',
      className,
      variant = 'primary',
      disabled = false,
      loading = false,
      type = 'button',
      children,
      state,
      loadingComponent = <DefaultLoader />,
      icon = null,
      iconOnly,
      iconPosition = 'trailing',
      ...props
    },
    ref,
  ) => {
    const renderChildren = () => {
      if (loading) return loadingComponent;

      if (iconOnly) {
        return icon;
      }

      if (iconPosition === 'trailing') {
        return [
          <Fragment key={'children'}>{children}</Fragment>,
          <Fragment key={'icon'}>{icon}</Fragment>,
        ];
      }

      return [
        <Fragment key={'icon'}>{icon}</Fragment>,
        <Fragment key={'children'}>{children}</Fragment>,
      ];
    };

    return (
      <button
        ref={ref}
        type={type}
        className={cn(
          buttonVariants({
            size,
            variant,
            state,
            iconOnly,
            disabled: disabled || loading,
          }),
          className,
        )}
        disabled={disabled || loading}
        {...props}
      >
        {renderChildren()}
      </button>
    );
  },
);
Button.displayName = 'Button';

export default Button;
