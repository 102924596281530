/* eslint-disable react/prop-types */
import { ChevronLeft, ChevronRight, MoreHorizontal } from 'lucide-react';
import * as React from 'react';

import { Button, ButtonProps, buttonVariants } from '../button';
import cn from '../lib/cn';

const Pagination = ({ className, ...props }: React.ComponentProps<'nav'>) => (
  <nav
    role="navigation"
    aria-label="pagination"
    className={cn('mx-auto flex w-full justify-center', className)}
    {...props}
  />
);
Pagination.displayName = 'Pagination';

const PaginationContent = React.forwardRef<
  HTMLUListElement,
  React.ComponentProps<'ul'>
>(({ className, ...props }, ref) => (
  <ul
    ref={ref}
    className={cn('flex flex-row items-center gap-1', className)}
    {...props}
  />
));
PaginationContent.displayName = 'PaginationContent';

const PaginationItem = React.forwardRef<
  HTMLLIElement,
  React.ComponentProps<'li'>
>(({ className, ...props }, ref) => (
  <li ref={ref} className={cn('', className)} {...props} />
));
PaginationItem.displayName = 'PaginationItem';

type PaginationLinkProps = {
  isActive?: boolean;
  disabled?: boolean;
} & Pick<ButtonProps, 'size' | 'icon' | 'iconOnly' | 'iconPosition'> &
  React.ComponentProps<'button'>;

const PaginationLink = ({
  className,
  isActive,
  disabled,
  size = 'sm',
  ...props
}: PaginationLinkProps) => (
  <Button
    aria-current={isActive ? 'page' : undefined}
    disabled={disabled}
    className={cn(
      buttonVariants({
        variant: isActive ? 'outline' : 'text',
        size,
        disabled,
      }),
      'b3 font-normal rounded-md disabled:bg-transparent disabled:border-transparent text-content-primary disabled:!text-content-tertiary hover:border-surface-hover hover:text-content-primary active:text-content-primary',
      isActive
        ? '!bg-surface-brand-light !border-surface-brand-primary'
        : 'hover:bg-gray-100 active:bg-gray-100 active:border-gray-100',
      className,
    )}
    {...props}
  />
);
PaginationLink.displayName = 'PaginationLink';

const PaginationPrevious = ({
  className,
  hideText = false,
  ...props
}: React.ComponentProps<typeof PaginationLink> & {
  hideText?: boolean;
}) => (
  <PaginationLink
    aria-label="Go to previous page"
    size="sm"
    className={cn(
      'h-9 w-9 hover:bg-surface-brand-light hover:border-surface-brand-light',
      !hideText && 'gap-1 w-auto',
      hideText && 'px-0',
      className,
    )}
    icon={<ChevronLeft fontWeight={'bold'} />}
    iconOnly={hideText}
    iconPosition="leading"
    {...props}
  >
    Previous
  </PaginationLink>
);
PaginationPrevious.displayName = 'PaginationPrevious';

const PaginationNext = ({
  className,
  hideText = false,
  ...props
}: React.ComponentProps<typeof PaginationLink> & {
  hideText?: boolean;
}) => (
  <PaginationLink
    aria-label="Go to next page"
    size="sm"
    className={cn(
      'h-9 w-9 hover:bg-surface-brand-light hover:border-surface-brand-light',
      !hideText && 'gap-1 w-auto',
      hideText && 'px-0',
      className,
    )}
    icon={<ChevronRight />}
    iconOnly={hideText}
    {...props}
  >
    Next
  </PaginationLink>
);
PaginationNext.displayName = 'PaginationNext';

const PaginationEllipsis = ({
  className,
  ...props
}: React.ComponentProps<'span'>) => (
  <span
    aria-hidden
    className={cn('flex h-9 w-9 items-center justify-center', className)}
    {...props}
  >
    <MoreHorizontal className="h-4 w-4" />
    <span className="sr-only">More pages</span>
  </span>
);
PaginationEllipsis.displayName = 'PaginationEllipsis';

export {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
};
