/* eslint-disable react/prop-types */
import * as TabsPrimitive from '@radix-ui/react-tabs';
import * as React from 'react';

import cn from '../lib/cn';

const TabsCore = TabsPrimitive.Root;

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> & { pill?: true }
>(({ className, pill, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      'inline-flex h-8 w-full items-center justify-start bg-transparent',
      pill ? 'gap-x-1' : 'border-b border-b-[rgba(226,232,240,1)] gap-x-4',
      className,
    )}
    {...props}
  />
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> & { pill?: true }
>(({ className, pill, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      'inline-flex items-center gap-x-1 justify-center whitespace-nowrap -mt-[1px] b3 font-medium py-1.5 bg-transparent text-content-secondary disabled:pointer-events-none disabled:opacity-50 data-[state=active]:text-content-primary',
      pill
        ? 'data-[state=active]:bg-surface-tertiary data-[state=active]:rounded-md px-3 py-1.5'
        : 'border-b-2 border-b-transparent data-[state=active]:border-b-content-brand-primary',
      className,
    )}
    {...props}
  />
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      'mt-3 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
      className,
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { TabsCore, TabsList, TabsTrigger, TabsContent };
